// Smooth scroll on anchors
document.addEventListener("DOMContentLoaded", () => {
  Array.from(document.querySelectorAll("a[href^='#'")).forEach((el) => {
    if (el.getAttribute("href") === "#") { return }
    el.addEventListener("click", (e) => {
      e.preventDefault();
      const scrollToElement = document.getElementById(
        el.getAttribute("href").slice(1)
      );
      const newScrollTop =
        window.scrollY + scrollToElement.getBoundingClientRect().y - 60;
      window.scrollTo({ top: newScrollTop, behavior: "smooth" });
    });
  });
});

window.scrollToBrowseApp = () => {
  const headerHeight = 112;
  const browseAppY = document.getElementById('browse-app').getBoundingClientRect().y + window.scrollY - headerHeight;
  window.scrollTo({ top: browseAppY, behavior: 'smooth' });
}
