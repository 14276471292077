import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";
import FullscreenPhotos from "../alpine/FullscreenPhotos";
import CookieBanner from "../alpine/CookieBanner";

Alpine.plugin(collapse)

Alpine.data("FullscreenPhotos", FullscreenPhotos);
Alpine.data("CookieBanner", CookieBanner);

window.Alpine = Alpine;
document.addEventListener("DOMContentLoaded", () => Alpine.start());
