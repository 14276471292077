declare global {
  interface Window {
    Cookiebot: any;
    CookiebotDialog: any;
  }
}

const EU_COUNTRIES = ["AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "DE", "GR", "HU", "IE", "IT", "LV", "LT", "LU", "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE"];

let retryCount = 0; function waitForCookiebot(t) { window.Cookiebot && window.Cookiebot.loaded ? t() : (retryCount += 1, retryCount < 10 && setTimeout(waitForCookiebot.bind(null, t), 1e3)) }

export default function () {
  return {
    isAcceptRejectStyle: false,
    isCookiebotLoaded: false,
    consentReady: false,
    init() {
      window.addEventListener("CookiebotOnConsentReady", function () {
        console.log("CookiebotOnConsentReady");
        this.consentReady = true;
      }.bind(this));
      waitForCookiebot(function () {
        this.isCookiebotLoaded = true;
        this.isAcceptRejectStyle = EU_COUNTRIES.includes(window.Cookiebot?.userCountry?.toUpperCase());
        this.consentReady = window.Cookiebot?.consented
      }.bind(this))
    }
  }
};

