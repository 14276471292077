import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";

if (window.location.hostname === "basejam.com") {
  Sentry.init({
    dsn: "https://ffd02e95d4cf42fdb0f8e6ba0c7580fc@o4504055178854400.ingest.sentry.io/4504197957222400",
    integrations: [
      new BrowserTracing(),
      new CaptureConsoleIntegration({
        levels: ["error"],
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  });

  document.addEventListener("turbo:before-fetch-request", (e) => {
    Sentry.addBreadcrumb({
      category: "request",
      message: `${e.detail?.fetchOptions?.method} ${e.detail?.fetchOptions?.body}`,
    });
  });

  document.addEventListener("turbo:before-fetch-response", (e) => {
    if (!e.detail?.fetchResponse?.succeeded) {
      Sentry.captureException(
        new Error(
          `Request failed ${
            e.detail?.fetchResponse?.response?.status
          } ${e.detail?.fetchResponse?.location?.toString()}`
        )
      );
    }
  });
}
