// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "core-js/stable";
import "regenerator-runtime/runtime";
import "@hotwired/turbo-rails";
import posthog from "posthog-js";

// Polyfills
import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();

// Turbo options
window.Turbo.setProgressBarDelay(100);

const originalWebSocketClose = WebSocket.prototype.close;
WebSocket.prototype.close = function () {
  if (this.readyState != WebSocket.CONNECTING) {
    originalWebSocketClose.apply(this, arguments);
  }
};

import debounced from "debounced";
debounced.initialize();
// import _ from "lodash";
import debounce from "lodash/debounce";
window.debounce = debounce;

// Configs
import "config/sentry";
import "config/aos";
import "config/alpine";
import "config/swiper";
import "flowbite";

// Clerk
// import { mountClerk } from "config/clerk";
// // document.documentElement.addEventListener("turbo:load", mountClerk());
// (async () => {
//   await mountClerk();
// })();

// Posthog

let posthogProjectId = "phc_OyMtdsBETUfR5Czd5qXsJgod1CG3o5loVpyRqKLZ3rB"; // Production
if (window.location.hostname === "localhost") {
  posthogProjectId = "phc_OsdTHqw9YAHM7eKdRyrpkSgsLMRz9E4sM78kZK42FSn"; // Local
}

window.posthog = posthog;

posthog.init(posthogProjectId, {
  api_host: "https://eu.posthog.com",
});

// Basejam code
// import "controllers";
import "features";
