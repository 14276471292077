import ScrollLock from "../utils/scroll-lock";

document.addEventListener("turbo:load", () => {
  const hamburger = document.getElementById("hamburger");
  const mobileMenu = document.getElementById("mobile-menu");

  const header = document.querySelector("header");

  if (!hamburger) {
    return;
  }

  hamburger.addEventListener("click", (e) => {
    e.preventDefault();

    if (hamburger.classList.contains("is-active")) {
      close();
    } else {
      open();
    }
  });

  const open = () => {
    // window.scroll({ top: 0, left: 0 }); //
    mobileMenu.style.opacity = "1.0";
    mobileMenu.style.visibility = "visible";
    mobileMenu.style.top = `${header.getBoundingClientRect().bottom}px`;
    // mobileMenu.style.height = `calc(100vh - ${header.getBoundingClientRect().height}px)`
    mobileMenu.style.height = `${
      window.innerHeight - header.getBoundingClientRect().height
    }px`;
    header.style.backgroundColor = "white";
    ScrollLock.enable();
    hamburger.classList.add("is-active");
  };

  const close = () => {
    mobileMenu.style.opacity = "0";
    ScrollLock.disable();
    hamburger.classList.remove("is-active");
    header.style.backgroundColor = null;
    setTimeout(() => {
      mobileMenu.style.visibility = "hidden";
    }, 150);
  };

  Array.from(mobileMenu.querySelectorAll("a[href^='#'")).forEach((el) => {
    el.addEventListener("click", (e) => {
      e.preventDefault();
      close();
      setTimeout(() => {
        const scrollToElement = document.getElementById(
          el.getAttribute("href").slice(1)
        );
        const newScrollTop =
          window.scrollY + scrollToElement.getBoundingClientRect().y - 60;
        window.scrollTo({ top: newScrollTop, behavior: "smooth" });
      }, 100);
    });
  });
});
