import ScrollLock from "../utils/scroll-lock";

export default function () {
  return {
    open: false,
    init() {
      this.$watch("open", function (value) {
        if (value === true) {
          ScrollLock.enable();
        } else {
          ScrollLock.disable();
        }
      });
    },
    modal: {
      ["x-show"]() { return this.open },
      ["x-on:keydown.escape.prevent.stop"]() { this.open = false }
    }
  }
};

